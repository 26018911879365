<template>
  <div class="keywords">
    <Multiselect
      :value="currentKeywords"
      :class="classes"
      class="control"
      :options="results"
      :options-limit="100"
      :multiple="true"
      :taggable="true"
      track-by="name"
      label="name"
      :reset-after="false"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      tag-placeholder=""
      placeholder="Rechercher un mot-clé"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="false"
      :preserve-search="false"
      @tag="addKeyword"
      @select="addKeyword"
      @search-change="searchKeywords"
      @remove="removeKeyword"
    >
      <span slot="noResult">
        {{ $t('searchbars.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('searchbars.noOptions') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import miscAPI from '@/api/miscAPI.js';

import Multiselect from 'vue-multiselect';

export default {
  name: 'Keywords',

  components: {
    Multiselect
  },

  props: {
    tags: {
      type: Array,
      default: () => { return []; }
    },
    classes: {
      type: Object,
      default: () => { 
        return {
          valid: 'is-valid',
          invalid: 'is-invalid'
        };
      }
    }
  },

  data() {
    return {
      loading: false,
      isAddingKeyword: false,
      currentKeywords: [],
      results: []
    };
  },

  watch: {
    tags: {
      deep: true,
      handler(newValue) {
        const keywords = newValue.map(el => {
          return {
            name: el
          };
        });
        this.currentKeywords = keywords;
      }
    }
  },

  created() {
    this.searchKeywords('');
  },

  methods: {
    searchKeywords(e) {
      this.loading = true;
      miscAPI.searchKeywords(e)
        .then((resp) => {
          this.results = resp.results.filter(el => { return el.name.length && el.name !== ' '; });
          this.loading = false;
        })
        .catch(() => {
          this.results = [];
          this.loading = false;
        });
    },

    addKeyword(e) {
      let value;
      if (e && e.name) {
        value = e.name;
      } else {
        value = e;
      }
      if (value && /(.|\s)*\S(.|\s)*/.test(value) && /[a-zA-Z0-9]/.test(value)) {
        const index = this.currentKeywords.findIndex(el => el.name === e.name);
        if (index === -1) {
          this.currentKeywords.push({ name: value });
        }
        const tags = this.currentKeywords.map(el => el.name);
        this.$emit('update', tags);
      }
    },
    removeKeyword(tag) {
      const index = this.currentKeywords.findIndex(el => el.name === tag.name);
      this.currentKeywords.splice(index, 1);
      const tags = this.currentKeywords.map(el => el.name);
      this.$emit('update', tags);
    }
  }
};
</script>

<style lang="less" scoped>
.multiselect__tags {
  white-space: normal;
  .multiselect__input {
    width: fit-content !important;
  }
}

.keywords-container.is-valid {
  border: 2px #078b40 solid;
}
.keywords-container.is-invalid {
  border: 2px #EB0600 solid;
}
</style>
