<template>
  <div class="page">
    <div
      v-if="routeFrom && routeFrom.name === 'Data'"
      class="back-link"
      @click="$router.push(routeFrom)"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($t('words.dataManagement')) }}
      </span>
    </div>
    <div
      v-else
      class="back-link"
      @click="$router.push({ name: 'Datasets' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ $t('addDataset.datasetsList') }}
      </span>
    </div>

    <b-container
      fluid
    >
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
      />

      <h2>{{ $t('addDataset.title') }}</h2>
      <ValidationObserver v-slot="{ handleSubmit, errors }">
        <b-row>
          <b-col
            cols="8"
            class="detail-forms"
            style="overflow-x: hidden;"
          >
            <form>
              <DatasetForm
                :mode="'addDataset'"
                :default-form="form"
                :usergroup="form.usergroup"
                @change="setForm"
              />
              <h3 class="line-header">
                <img
                  src="@/assets/icons/form_description_file.svg"
                  alt="Icon file document sheet"
                > {{ $t('addDataset.mainResource') }}
              </h3>
              <div style="margin-left: 1em;">
                <ResourceCreator
                  :key="'resource-creator'"
                  :required="false"
                  @set="setResource"
                />
              </div>
              <h3
                class="line-header"
              >
                <img
                  src="@/assets/icons/files_ressources.svg"
                  alt="Icon files ressources"
                > {{ $t('addDataset.annexes') }}
              </h3>
              <div style="margin-left: 1em;">
                <ResourceCreator
                  :key="'annex-creator'"
                  :is-main="false"
                  :required="false"
                  @set="setAnnexe"
                />
              </div>
            </form>
          </b-col>
          <b-col
            cols="4"
          >
            <div class="side-card-container">
              <div class="card-scroller">
                <DatasetCard
                  :key="`dataset-card-${cardKey}`"
                  :dataset="form"
                  @set="setThumbnail"
                />
                <div class="parent-dropdown">
                  <label>
                    {{ $t('forms.datasets.organisation.label') }}
                  </label>
                  <SearchUsergroups
                    v-model="form.usergroup"
                    :types="['organisation', 'user-group']"
                    :placeholder="$t('forms.datasets.organisation.help')"
                    :usergroup="form.usergroup"
                    :disabled="false"
                    :reset-after="false"
                    :limit="10"
                    :allow-null="true"
                    @select="setUsergroup"
                    @close="setUsergroup"
                    @remove="setUsergroup"
                  />
                </div>
                <div class="parent-dropdown">
                  <label>
                    {{ $t('datasetDetail.parentDataset.title') }}
                  </label>
                  <SearchDatasets
                    v-model="form.parent"
                    :dataset="form.parent"
                    :usergroup="form.usergroup"
                    :placeholder="$t('datasetDetail.parentDataset.placeholder')"
                    @select="setParentDataset"
                    @close="setParentDataset"
                  />
                </div>
                <div class="record_type-dropdown">
                  <label style="margin-bottom: 0.5rem;">
                    {{ $t('datasetDetail.recordType') }}
                  </label>
                  <Multiselect
                    v-model="form.recordType"
                    :options="datasetsRecordTypes"
                    track-by="choice"
                    label="label"
                    select-label=""
                    selected-label=""
                    deselect-label=""
                    :searchable="false"
                    :placeholder="$t('datasetDetail.recordType')"
                  />
                </div>
                <div class="side-footer">
                  <!-- FORM FOOTER -->
                  <b-button
                    id="create-dataset-with-annexe"
                    variant="primary"
                    @click="handleSubmit(createDataset)"
                  >
                    {{ $t('buttons.save') }}
                  </b-button>
                  <!-- FORM ERRORS -->
                  <div
                    v-if="Object.values(errors).some(el => el.length > 0)"
                    class="error-messages alert alert-danger"
                    role="alert"
                  >
                    <b-icon
                      icon="exclamation-triangle-fill"
                      font-scale="2"
                    />
                    <h5 class="alert-heading">
                      {{ $t('addDataset.error') }}
                    </h5>
                    <ul>
                      <div
                        v-for="(error, field) in errors"
                        :key="field"
                      >
                        <li
                          v-if="error[0]"
                        >
                          <b>{{ field }} :</b> {{ error[0] }}.
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </ValidationObserver>
    </b-container>
    <SelectScenario
      :open="selectingScenario"
      :scenarios="possibleScenarios"
      @create="finishCreateResource"
    />
  </div>
</template>

<script>
import DatasetCard from '@/components/Cards/DatasetCard.vue';
import DatasetForm from '@/components/Forms/DatasetForm.vue';
import ResourceCreator from '@/components/Resource/ResourceCreator.vue';
import SelectScenario from '@/components/Modals/SelectScenario.vue';
import SearchDatasets from '@/components/Searchbars/SearchDatasets';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';
import Multiselect from 'vue-multiselect';

import { ValidationObserver } from 'vee-validate';

import { mapState, mapMutations, mapActions } from 'vuex';

import { COVERAGE_OPTIONS } from '@/constants.js';
import { slugify } from '@/utils';

import datasetsAPI from '@/api/datasetsAPI.js';

export default {
  name: 'AddDataset',

  components: {
    ValidationObserver,
    DatasetCard,
    DatasetForm,
    ResourceCreator,
    SelectScenario,
    SearchDatasets,
    SearchUsergroups,
    Multiselect
  },

  data() {
    return {
      routeFrom: null,
      loading: false,
      form: {
        display_name: null,
        description: null,
        keywords: [],
        categories: [],
        topics: [],
        kinds: [],
        recommendation: null,
        restriction: null,
        usergroup: null,
        parent: null,
        recordType: null,
        datasetRoles: [],
        licence: null,
        copyright: null,
        granularity: null,
        coverage: null,
        frequence: null,
        publicationDate: null,
        lastUpdateDate: null,
        year: null,
        lineage: null,
        resource: null,
        annexe: null,
        thumbnail: null
      },
      selectingScenario: false,
      possibleScenarios: [],
      selectedScenario: null,
      resourceCreationData: null,
      cardKey: 1
    };
  },

  computed: {
    ...mapState('user-login', [
      'userData'
    ]),
    ...mapState('organisations', [
      'organisationsList'
    ]),
    ...mapState('datasets', [
      'datasetsCategories',
      'datasetsGranularities',
      'datasetsLicences',
      'datasetsTopics',
      'datasetsKinds',
      'datasetsRestrictions',
      'datasetsUpdateFrequencies',
      'lastCreatedDataset',
      'duplicatedDataset',
      'duplicatedDatasetRoles',
      'currentDatasetRoles',
      'datasetsRecordTypes'
    ]),
    ...mapState('resources', [
      'resourceDataFormats',
      'resourceInspectionData',
      'currentResourceId',
      'lastCreatedResource',
      'currentResourceScenarios'
    ])
  },

  async created() {
    this.GET_DATASET_STATIC_DATA();
    if (!this.userData.is_superuser) {
      this.SET_USERGROUPS_FILTERS({
        filter: 'usergroup__status__in',
        value: 2
      });
      this.SET_USERGROUPS_FILTERS({
        filter: 'user__role__in',
        value: ['1', '2']
      });
    }

    if (this.duplicatedDataset) {
      this.form.display_name = this.duplicatedDataset.display_name.concat(' ', '(copie)');
      this.form.description = this.duplicatedDataset.description;
      this.form.usergroup = this.duplicatedDataset.usergroup;

      this.form.thumbnail = this.duplicatedDataset.thumbnail;

      // Roles
      let order = 1;
      this.form.datasetRoles.splice(0);
      for (let role of this.duplicatedDatasetRoles) {
        role.order = order;
        const contact = await datasetsAPI.getContact(role.contact.id);
        role.contact = contact;
        if (contact.usergroup && contact.usergroup.id) {
          role.contact.usergroup = JSON.parse(JSON.stringify(contact.usergroup.id));
        } else {
          role.contact.usergroup = null;
        }
        this.form.datasetRoles.push(role);
        order += 1;
      }

      this.form.categories = this.duplicatedDataset.categories.map(el => el.display_name);
      this.form.topics = this.duplicatedDataset.topics.map(el => el.display_name);
      this.form.kinds = this.duplicatedDataset.kinds.map(el => el.id);
      this.form.licence = this.duplicatedDataset.license;
      this.form.copyright = this.duplicatedDataset.copyright;
      this.form.granularity = this.duplicatedDataset.granularity ? this.duplicatedDataset.granularity : '';
      this.form.coverage =
        COVERAGE_OPTIONS.find(el => el.code === this.duplicatedDataset.geographical_coverage) ?
          COVERAGE_OPTIONS.find(el => el.code === this.duplicatedDataset.geographical_coverage) : '';
      this.form.keywords = this.duplicatedDataset.tags;
      this.form.recommendation = this.duplicatedDataset.recommendation;
      this.form.restriction = this.duplicatedDataset.restriction ?
        this.datasetsRestrictions.find(el => el === this.duplicatedDataset.restriction) ?
          this.datasetsRestrictions.find(el => el === this.duplicatedDataset.restriction) :
          this.duplicatedDataset.restriction :
        null;
      this.form.publicationDate = this.duplicatedDataset.publication_date ? this.duplicatedDataset.publication_date.slice(0, 10) : '';
      this.form.lastUpdateDate = this.duplicatedDataset.last_update_date ? this.duplicatedDataset.last_update_date.slice(0, 10) : '';
      this.form.creationDate = null;
      this.form.revisionDate = null;
      this.form.frequence =
        this.duplicatedDataset.update_frequency ?
          this.datasetsUpdateFrequencies.find(el => el.value === this.duplicatedDataset.update_frequency) :
          null,
      this.form.year = this.duplicatedDataset.year;
      this.form.lineage = this.duplicatedDataset.lineage;

      if (this.duplicatedDataset.record_type && this.duplicatedDataset.record_type.value) {
        this.form.recordType = {
          choice: this.duplicatedDataset.record_type.value,
          label: this.duplicatedDataset.record_type.label
        };
      }

      this.cardKey += 1;
    }

    this.UPDATE_CURRENT_DATASET_USERGROUP(null);
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.routeFrom = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    this.RESET_USERGROUPS_FILTERS();
    this.SET_DUPLICATED_DATASET(null);
    next();
  },

  methods: {
    ...mapMutations('usergroups', [
      'SET_USERGROUPS_FILTERS',
      'RESET_USERGROUPS_FILTERS'
    ]),
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapMutations('resources', [
      'SET_RESOURCE_INSPECTION_DATA',
    ]),
    ...mapMutations('datasets', [
      'SET_DUPLICATED_DATASET'
    ]),
    ...mapActions('datasets', [
      'GET_DATASET_STATIC_DATA',
      'GET_DATASETS_LIST',
      'CREATE_DATASET',
      'UPDATE_DATASET',
      'UPDATE_CURRENT_DATASET_USERGROUP'
    ]),
    ...mapActions('resources', [
      'DELETE_RESOURCE_FILE',
      'CREATE_FILE_UPLOAD_ANNEXE',
      'CREATE_FTP_ANNEXE',
      'CREATE_HREF_ANNEXE',
      'CREATE_FILE_DOWNLOAD_ANNEXE',
      'CREATE_DATASTORE_ANNEXE',
      'START_CREATE_RESOURCE',
      'FINISH_CREATE_RESOURCE'
    ]),
    ...mapActions('dashboard', [
      'GET_DASHBOARD_TASKS'
    ]),

    setForm(e) {
      this.form = e;
    },
    setThumbnail(e) {
      this.form.thumbnail = e;
    },

    setUsergroup(e) {
      if (e !== this.form.usergroup) {
        this.UPDATE_CURRENT_DATASET_USERGROUP(e);
      }
      this.form.usergroup = e;
    },

    setParentDataset(e) {
      this.form.parent = e ? e.id : null;
    },

    setResource(e) {
      this.form.resource = e;
    },

    setAnnexe(e) {
      this.form.annexe = e;
    },

    async createDataset() {
      try {
        const datasetData = {
          display_name: this.form.display_name,
          codename: slugify(this.form.display_name).substring(0, 100),
          usergroup: this.form.usergroup ? this.form.usergroup.id : '',
          parent: this.form.parent,
          record_type_value: this.form.recordType ? this.form.recordType.choice : null,
          usergroup_type: this.form.usergroup && this.form.usergroup.usergroup_type ? this.form.usergroup.usergroup_type.codename : '',
          is_active: true,
          description: this.form.description,
          thumbnail: this.form.thumbnail,
          categories: this.datasetsCategories.filter(el => this.form.categories.includes(el.display_name)),
          topics: this.datasetsTopics.filter(el => this.form.topics.includes(el.display_name)),
          kinds: this.datasetsKinds.filter(el => this.form.kinds.includes(el.id)),
          ...this.form.recommendation && { recommendation: this.form.recommendation },
          ...this.form.restriction && { restriction: this.form.restriction },
          tags: [...this.form.keywords],
          copyright: this.form.copyright,
          ...this.form.publicationDate && {
            publication_date: new Date(this.form.publicationDate).toISOString().split('T')[0]
          },
          ...this.form.lastUpdateDate && {
            last_update_date: new Date(this.form.lastUpdateDate).toISOString().split('T')[0]
          },
          ...this.form.frequence && {
            update_frequency: this.form.frequence.value
          },
          year: this.form.year,
          lineage: this.form.lineage,
          ...this.form.licence && { license: this.form.licence },
          ...this.form.granularity && { granularity: this.form.granularity },
          ...this.form.coverage && { geographical_coverage: this.form.coverage.code }
        };

        // Add extras
        if (
          this.$config.forms.dataset.extraFields &&
          this.$config.forms.dataset.extraFields.length
        ) {
          const extras = [];
          for (const extraField of this.$config.forms.dataset.extraFields) {
            for (const field of extraField.fields) {
              extras.push({
                key: field.key,
                value: this.form[field.key] && this.form[field.key].length ? this.form[field.key] : null
              });
            }
          }
          datasetData.extras = extras;
        }

        this.loading = true;

        await this.CREATE_DATASET(datasetData);
        if (this.lastCreatedDataset) {
          if(
            this.form.datasetRoles.length > 0 &&
            this.form.datasetRoles[this.form.datasetRoles.length - 1].contact.name &&
            this.form.datasetRoles[this.form.datasetRoles.length - 1].contact.email
          ) {
            // eslint-disable-next-line no-unused-vars
            const contactRoles = this.form.datasetRoles.map(({ order, ...el }) => { return el; });
            // Create new contacts
            for (let [i, role] of contactRoles.entries()) {
              if (!role.contact.id) {
                try {
                  role.contact.usergroup_id = role.contact.usergroup;
                  const newContact = await datasetsAPI.createContact(role.contact);
                  contactRoles[i].contact.id = newContact.id;
                } catch (err) {
                  console.error(err);
                }
              }
            }
            // Create roles
            for (const role of contactRoles) {
              await datasetsAPI.createDatasetRole(this.lastCreatedDataset.id, {
                role: role.role,
                contact_id: role.contact.id,
                main: role.main
              });
            }
          }
          if (this.form.annexe && this.form.annexe.type) {
            this.createAnnexe();
          } else if (this.form.resource) {
            this.startCreateResource();
          } else {
            if (this.lastCreatedDataset.id) {
              try {
                const data = {
                  ...this.lastCreatedDataset,
                  usergroup: this.lastCreatedDataset.usergroup.id
                };
                await this.UPDATE_DATASET({
                  id: this.lastCreatedDataset.id,
                  data: data
                });
                this.$router.push({ name: 'DatasetDetail', params: { id: this.lastCreatedDataset.id } });
              } catch {
                this.$router.push({ name: 'DatasetDetail', params: { id: this.lastCreatedDataset.id } });
              }
            }
          }
        } else {
          this.loading = false;
        }
      } catch(err) {
        console.error(err);
        this.loading = false;
      }
    },

    async createAnnexe() {
      try {
        if (this.form.annexe.type === 'file-upload') {
          const resourceAnnexeData = {
            ...this.form.annexe,
            usergroup_id: this.lastCreatedDataset.usergroup.id,
            dataset: this.lastCreatedDataset.id
          };
          this.createFileUploadAnnexe(resourceAnnexeData);
        } else if (this.form.annexe.type === 'ftp') {
          const resourceAnnexeData = {
            ...this.form.annexe,
            dataformat_id: this.form.annexe.format.id,
            usergroup_id: this.form.usergroup.id,
            dataset: this.lastCreatedDataset.id,
            href: this.form.annexe.selectedFtpFiles.map(el => el.value),
          };
          this.createFTPAnnexe(resourceAnnexeData);
        } else if (this.form.annexe.type === 'href') {
          const resourceAnnexeData = {
            ...this.form.annexe,
            usergroup_id: this.form.usergroup.id,
            dataset: this.lastCreatedDataset.id,
            href: this.form.annexe.href,
          };
          this.createHrefAnnexe(resourceAnnexeData);
        } else if (this.form.annexe.type === 'file-download') {
          const resourceAnnexeData = {
            ...this.form.annexe,
            usergroup_id: this.form.usergroup.id,
            dataset: this.lastCreatedDataset.id,
            href: this.form.annexe.href,
          };
          this.createFileDownloadAnnexe(resourceAnnexeData);
        } else if (this.form.annexe.type === 'datastore') {
          const resourceAnnexeData = {
            ...this.form.annexe,
            usergroup_id: this.form.usergroup.id,
            dataset: this.lastCreatedDataset.id,
            // href: this.form.annexe.href,
          };
          this.createDatastoreAnnexe(resourceAnnexeData);
        }
      } catch (err) {
        console.error(err);
        if (this.form.resource) {
          this.startCreateResource();
        } else {
          if (this.lastCreatedDataset.id) {
            try {
              const data = {
                ...this.lastCreatedDataset,
                usergroup: this.lastCreatedDataset.usergroup.id
              };
              await this.UPDATE_DATASET({
                id: this.lastCreatedDataset.id,
                data: data
              });
              this.$router.push({ name: 'DatasetDetail', params: { id: this.lastCreatedDataset.id } });
            } catch {
              this.$router.push({ name: 'DatasetDetail', params: { id: this.lastCreatedDataset.id } });
            }
          }
        }
      }
    },

    createFileUploadAnnexe(resourceAnnexeData) {
      this.CREATE_FILE_UPLOAD_ANNEXE(resourceAnnexeData)
        .then(() => {
          if (this.form.resource) {
            this.startCreateResource(resourceAnnexeData);
          } else {
            const data = {
              ...this.lastCreatedDataset,
              usergroup: this.lastCreatedDataset.usergroup.id
            };
            this.UPDATE_DATASET({
              id: this.lastCreatedDataset.id,
              data: data
            })
              .then(() => {
                this.afterCreationRequests();
                this.loading = false;
              })
              .catch(() => {
                this.afterCreationRequests();
                this.loading =false;
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createFTPAnnexe(resourceAnnexeData) {
      this.CREATE_FTP_ANNEXE(resourceAnnexeData)
        .then(() => {
          if (this.form.resource) {
            this.startCreateResource(resourceAnnexeData);
          } else {
            const data = {
              ...this.lastCreatedDataset,
              usergroup: this.lastCreatedDataset.usergroup.id
            };
            this.UPDATE_DATASET({
              id: this.lastCreatedDataset.id,
              data: data
            })
              .then(() => {
                this.afterCreationRequests();
                this.loading = false;
              })
              .catch(() => {
                this.afterCreationRequests();
                this.loading =false;
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createHrefAnnexe(resourceAnnexeData) {
      this.CREATE_HREF_ANNEXE(resourceAnnexeData)
        .then(() => {
          if (this.form.resource) {
            this.startCreateResource();
          } else {
            const data = {
              ...this.lastCreatedDataset,
              usergroup: this.lastCreatedDataset.usergroup.id
            };
            this.UPDATE_DATASET({
              id: this.lastCreatedDataset.id,
              data: data
            })
              .then(() => {
                this.afterCreationRequests();
                this.loading = false;
              })
              .catch(() => {
                this.afterCreationRequests();
                this.loading =false;
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createFileDownloadAnnexe(resourceAnnexeData) {
      this.CREATE_FILE_DOWNLOAD_ANNEXE(resourceAnnexeData)
        .then(() => {
          if (this.form.resource) {
            this.startCreateResource();
          } else {
            const data = {
              ...this.lastCreatedDataset,
              usergroup: this.lastCreatedDataset.usergroup.id
            };
            this.UPDATE_DATASET({
              id: this.lastCreatedDataset.id,
              data: data
            })
              .then(() => {
                this.afterCreationRequests();
                this.loading = false;
              })
              .catch(() => {
                this.afterCreationRequests();
                this.loading =false;
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    createDatastoreAnnexe(resourceAnnexeData) {
      this.CREATE_DATASTORE_ANNEXE(resourceAnnexeData)
        .then(() => {
          if (this.form.resource) {
            this.startCreateResource();
          } else {
            const data = {
              ...this.lastCreatedDataset,
              usergroup: this.lastCreatedDataset.usergroup.id
            };
            this.UPDATE_DATASET({
              id: this.lastCreatedDataset.id,
              data: data
            })
              .then(() => {
                this.afterCreationRequests();
                this.loading = false;
              })
              .catch(() => {
                this.afterCreationRequests();
                this.loading =false;
              });
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    async startCreateResource() {
      try {
        let datastoreType = null;
        let scheduler;

        const { format, ...resourceForm } = this.form.resource;
        if (this.form.resource.type === 'file-upload') {
          this.resourceCreationData = {
            ...resourceForm,
            organisation: this.form.usergroup.id,
            dataformat_id: format.id,
            file: this.form.resource.file
          };
        } else if (this.form.resource.type === 'ftp') {
          this.resourceCreationData = {
            ...resourceForm,
            dataformat_id: format.id,
            href: this.form.resource.selectedFtpFiles.map(el => el.value),
          };
        } else if (this.form.resource.type === 'href') {
          this.resourceCreationData = {
            ...resourceForm,
            dataformat_id: format.id,
            href: this.form.resource.href,
          };
        } else if (this.form.resource.type === 'file-download') {
          this.resourceCreationData = {
            ...resourceForm,
            dataformat_id: format.id,
            href: this.form.resource.href,
          };
        } else if (this.form.resource.type === 'file-download') {
          this.resourceCreationData = {
            ...this.form.resource,
            data_format: this.form.resource.format.codename,
            href: this.form.resource.href,
          };
        } else if (this.form.resource.type === 'datastore') {
          this.resourceCreationData = {
            ...resourceForm,
          };
          datastoreType = this.form.resource.format;
        } else if (this.form.resource.type === 'geoserver') {
          this.resourceCreationData = {
            ...resourceForm,
          };
        }

        if (['ftp', 'href', 'file-download'].includes(this.form.resource.type)) {
          scheduler = this.form.resource.scheduler;
        }

        await this.START_CREATE_RESOURCE({
          typeResource: this.form.resource.type,
          data: this.resourceCreationData,
          datastoreType: datastoreType
        });

        if (this.currentResourceScenarios.create && this.currentResourceScenarios.create.length) {
          this.possibleScenarios = this.currentResourceScenarios.create;
          if (this.possibleScenarios.length > 1) {
            this.selectingScenario = true;
          } else {
            this.finishCreateResource(this.possibleScenarios[0].id, scheduler);
          }
        } else {
          console.error('Pas de scénario ...');
          this.loadingResource = false;
          this.afterCreationRequests();
        }

      } catch (err) {
        console.error(err);
        this.loadingResource = false;
        this.afterCreationRequests();
      }
    },

    async finishCreateResource(action, scheduler) {
      try {
        this.selectingScenario = false;
        const finishResourceCreationData = {
          kwargs: {
            resource__display_name: this.resourceCreationData.display_name,
            ...this.resourceCreationData.geographiclayerDisplayName && {
              geographiclayer__display_name: this.resourceCreationData.geographiclayerDisplayName
            },
            ...this.resourceCreationData.geographiclayerCodename && {
              geographiclayer__codename: this.resourceCreationData.geographiclayerCodename
            },
            resource__description: this.resourceCreationData.description,
            resource__kind_id:
              this.resourceCreationData.kind && this.resourceCreationData.kind.id ?
                this.resourceCreationData.kind.id : null,
            resource__last_update_date: this.resourceCreationData.lastUpdateDate,
            usergroup_id: this.form.usergroup.id,
            dataset_id: this.lastCreatedDataset.id,
            target: {
              app_label: 'onegeo_dataset',
              model: 'dataset',
              pk: this.lastCreatedDataset.id
            }
          }
        };

        await this.FINISH_CREATE_RESOURCE({
          typeResource: this.form.resource.type,
          action: action,
          data: finishResourceCreationData,
          scheduler: scheduler,
          ...this.form.resource.type === 'datastore' && { datastoreType: this.form.resource.format },
          ...this.form.resource.type === 'geoserver' && { layerType: this.form.resource.layerType },
          async: true
        });

        this.resourceCreationData = null;
        setTimeout(() => {
          this.afterCreationRequests(),
          2000;
        });
      } catch (err) {
        console.error(err);
        this.resourceCreationData = null;
        this.afterCreationRequests();
      }
    },

    // Fired after each resource creation
    afterCreationRequests() {
      this.GET_DATASETS_LIST({
        direction: null,
        field: null
      });
      this.SET_CURRENT_FORM({
        id: null,
        form: null
      });
      this.GET_DASHBOARD_TASKS({
        direction: null,
        field: null
      })
        .then(() => {
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });

      if (this.lastCreatedDataset.id) {
        this.$router.push({ name: 'DatasetDetail', params: { id: this.lastCreatedDataset.id } });
      }
    },

    cancelResourceCreation() {
      this.DELETE_RESOURCE_FILE(this.currentResourceId);
    }
  }
};
</script>

<style lang="less" scoped>
.add-dataset-form {
  margin-left: max(2em, auto);
  margin-right: max(2em, auto);
  form {
    max-width: 1000px;
  }
}

h2 {
  color: @blue;
  text-shadow: 0 0 2px @lightBlue;
  margin-top: 0.8em;
  margin-left: 0.5em;
}

</style>
