<template>
  <div class="resource_meta_data">
    <!-- DISPLAY NAME -->
    <div class="form-row">
      <div class="form-group col-12">
        <label :class="isFieldRequired('display_name', formConfig.requiredFields)">
          {{ $t('resource.name.label') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          ref="display_name"
          :rules="isRequired ? isFieldRequired('display_name', formConfig.requiredFields) : ''"
          :name="`Nom ${isMain ? 'ressource' : 'annexe'}`"
        >
          <div
            class="control"
            :class="classes"
          >
            <input
              v-model="form.displayName"
              v-sanitize
              class="form-control"
              type="text"
              :placeholder="isMain ?
                $t('resource.name.resourcePlaceholder') : $t('resource.name.annexPlaceholder')
              "
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>

    <!-- DESCRIPTION -->
    <div class="form-row">
      <div class="form-group col-12">
        <label>
          {{ $t('resource.description') }}
        </label>
        <textarea
          v-model="form.description"
          v-sanitize
          class="form-control"
        />
      </div>
    </div>

    <div class="form-row">
      <!-- KIND -->
      <div
        v-if="isFieldVisible('kind', formConfig.hiddenFields)"
        class="form-group col-6"
      >
        <label
          :class="isRequired ? isFieldRequired('kind', formConfig.requiredFields) : ''"
        >
          {{ $t('resource.type.label') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          ref="kind"
          :rules="isRequired ? isFieldRequired('kind', formConfig.requiredFields) : ''"
          name="Type"
        >
          <div
            class="control"
            :class="classes"
          >
            <Multiselect
              v-model="form.kind"
              :options="resourceKinds"
              :class="classes"
              track-by="id"
              label="display_name"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.type.placeholder')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>

      <!-- LAST UPDATE DATE -->
      <div class="form-group col-6">
        <label
          :class="isMain ? isFieldRequired('lastUpdateDate', formConfig.requiredFields) : ''"
        >
          {{ $t('resource.lastUpdateDate') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          ref="lastUpdateDate"
          :rules="isMain ? isFieldRequired('lastUpdateDate', formConfig.requiredFields) : ''"
          :name="`Date de dernière mise à jour ${isMain ? 'ressource' : 'annexe'}`"
        >
          <div
            class="control"
            :class="isMain ? classes : ''"
          >
            <input
              v-model="form.lastUpdateDate"
              :class="{ empty: !form.lastUpdateDate}"
              class="form-control"
              type="date"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import Multiselect from 'vue-multiselect';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'ResourceMetaData',

  components: {
    Multiselect,
    ValidationProvider
  },

  props: {
    isMain: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: true
    },
    formConfig: {
      type: Object,
      default: () => {
        return {
          hiddenFields: [],
          requiredFields: []
        };
      }
    },
    metaData: {
      type: Object,
      default: () => {
        return {
          displayName: null,
          description: null,
          kind: null,
          lastUpdateDate: null
        };
      }
    }
  },

  data() {
    return {
      isRequired: true,
      form: {
        displayName: null,
        description: null,
        kind: null,
        lastUpdateDate: null
      }
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats',
      'resourceKinds'
    ])
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },
    form: {
      deep: true,
      handler(newValue) {
        this.setForm(newValue);
      }
    }
  },

  created() {
    this.isRequired = this.required;
    this.setForm(this.metaData);
  },

  methods: {
    setForm(formData) {
      this.form.displayName = formData.displayName;
      this.form.description = formData.description;
      this.form.kind = formData.kind;
      this.form.lastUpdateDate = formData.lastUpdateDate;
      this.$emit('set', {
        form: this.form,
      });
    }
  }
};
</script>
