<template>
  <div :class="disabled ? 'disabled' : ''">
    <b-overlay
      :show="busy"
      rounded="lg"
      opacity="0.6"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <b-spinner
            type="grow"
            variant="dark"
          />
          <b-spinner
            small
            type="grow"
            variant="secondary"
          />
          <span class="sr-only">Please wait...</span>
        </div>
      </template>
      <Multiselect
        ref="multiselect"
        v-model="selection"
        style="margin-top: 0.5em;"
        class="search-datasets"
        :options="results"
        :options-limit="limit"
        :allow-empty="true"
        track-by="id"
        label="display_name"
        :reset-after="false"
        select-label=""
        selected-label=""
        deselect-label=""
        :searchable="true"
        :placeholder="placeholder"
        :show-no-results="true"
        :loading="loading"
        :clear-on-select="false"
        :preserve-search="true"
        @search-change="search"
        @select="select"
        @close="close"
      >
        <template slot="clear">
          <div
            v-if="selection"
            class="multiselect__clear"
            @click.prevent.stop="selection = null; $refs.multiselect.deactivate();"
          >
            <b-icon-x
              v-if="!disabled"
              font-scale="2"
            />
          </div>
        </template>
        <span slot="noResult">
          {{ $t('searchbars.noResult') }}
        </span>
        <span slot="noOptions">
          {{ $t('searchbars.noOptions') }}
        </span>
      </Multiselect>
    </b-overlay>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapMutations, mapActions } from 'vuex';

import datasetsAPI from '@/api/datasetsAPI.js';

export default {
  name: 'SearchDatasets',

  components: {
    Multiselect
  },

  props: {
    busy: {
      type: Boolean,
      default: false
    },
    dataset: {
      type: Number,
      default: null
    },
    usergroup: {
      type: Object,
      default: () => { return {}; }
    },
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('datasets', [
      'datasetsList',
      'currentDataset'
    ]),

    parentDatasetKinds() {
      if (
        this.$config.datasets &&
        this.$config.datasets.parentKinds &&
        this.$config.datasets.parentKinds.length
      ) {
        return this.$config.datasets.parentKinds;
      }
      return null;
    }
  },

  watch: {
    dataset: {
      deep: true,
      async handler(newValue) {
        if (newValue) {
          try {
            this.loading = true;
            const dataset = await datasetsAPI.getDataset(newValue);
            if (dataset) {
              this.selection = dataset;
            }
            this.loading = false;
          } catch (err) {
            console.error(err);
            this.loading = false;
          }
        }
      }
    },
    usergroup: {
      deep: true,
      async handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.loading = true;
          try {
            await this.getDatasetsList();
            this.loading = false;
          } catch {
            this.loading = false;
          }
        }
      }
    },
    text: function(newValue) {
      this.loading = true;
      this.SEARCH_DATASETS_LIST({
        text: newValue,
        ordering: {
          direction: '',
          field: 'display_name'
        },
        kinds: this.parentDatasetKinds
      })
        .then(() => {
          this.results = this.datasetsList.filter(el => {
            if (this.currentDataset.id) {
              return el.id !== this.currentDataset.id &&
                      !this.currentDataset.children.find(child => child.id === el.id);
            } else {
              return true;
            }
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    }
  },

  async created() {
    this.loading = true;
    try {
      await this.getDatasetsList();
      if (this.dataset && this.dataset.id) {
        this.selection = this.dataset;
      }
      this.loading = false;
    } catch {
      this.loading = false;
    }
  },

  mounted() {
    document.addEventListener('click', this.$refs.multiselect.deactivate());
  },

  methods: {
    ...mapMutations('datasets', [
      'SET_IS_DATASETSLIST_SEARCHED'
    ]),
    ...mapActions('datasets', [
      'SEARCH_DATASETS_LIST',
      'GET_DATASETS_LIST'
    ]),

    async getDatasetsList() {
      await this.GET_DATASETS_LIST({
        direction: '',
        field: 'display_name',
        kinds: this.parentDatasetKinds
      });
      this.results = this.datasetsList.filter(el => {
        if (this.currentDataset.id) {
          return el.id !== this.currentDataset.id &&
                  !this.currentDataset.children.find(child => child.id === el.id);
        } else {
          return true;
        }
      });
    },

    search(text) {
      this.text = text;
    },

    select(e) {
      this.$emit('select', e);
      this.SET_IS_DATASETSLIST_SEARCHED({
        isSearched: false,
        text: null
      });
    },
    close() {
      this.$emit('close', this.selection);
      this.SET_IS_DATASETSLIST_SEARCHED({
        isSearched: false,
        text: null
      });
    }
  }
};
</script>

<style lang="less" scoped>
.disabled {
  pointer-events: none;
  opacity: 0.6;
}
</style>
