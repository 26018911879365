<template>
  <div>
    <div class="form-row">
      <b-overlay
        id="overlay-background"
        :show="loading"
        :variant="'white'"
        :opacity="0.7"
        :blur="'2px'"
        rounded="sm"
        no-wrap
      />
      <div class="form-group col-6">
        <label 
          class="required"
          style="margin-top: 1em;"
        >
          {{ $t('resource.format.label') }}
        </label>
        <p>
          {{ $t('resource.format.help') }}
        </p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Format FTP"
          :rules="`${isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="isRequired ? classes : ''"
          >
            <Multiselect
              v-model="form.format"
              :options="resourceDataFormats"
              :class="isRequired ? classes : ''"
              track-by="codename"
              label="display_name"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.format.placeholder')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <!-- <div
        v-if="usedFor === 'resource'"
        class="form-group col-6"
      >
        <label style="margin-top: 1em;">
          {{ $t('resource.scheduler.label') }}
        </label>
        <p>
          {{ $t('resource.scheduler.help') }}
        </p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Frequance FTP"
        >
          <div class="control">
            <Multiselect
              v-model="form.scheduler"
              :options="schedulers"
              track-by="id"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.scheduler.placeholder')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div> -->
    </div>
    <div class="form-row">
      <div class="form-group col">
        <label 
          class="required"
          style="margin-top: 1em;"
        >
          {{ $t('resource.ftp.files.label') }}
        </label>
        <p>
          {{ $t('resource.ftp.files.help') }}
        </p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Fichier FTP"
          :rules="`${isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="isRequired ? classes : ''"
          >
            <Multiselect
              v-model="form.selectedFtpFiles"
              class="ftp-choices"
              :options="ftpChoices"
              :multiple="true"
              track-by="value"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :close-on-select="false"
              :placeholder="$t('resource.ftp.files.placeholder')"
              @select="onSelect"
              @remove="onRemove"
            >
              <span
                slot="option"
                slot-scope="scope"
                class="checkbox-label"
                @click.self="onSelect(scope.option)"
              >
                {{ scope.option.label }}
                <input
                  v-model="scope.option.checked"
                  class="checkbox"
                  type="checkbox"
                  @focus.prevent
                >
              </span>
            </Multiselect>
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'FTPCreator',

  components: {
    ValidationProvider,
    Multiselect
  },

  props: {
    required: {
      type: Boolean,
      default: true
    },
    usedFor: {
      type: String,
      default: 'resource'
    },
  },

  data() {
    return {
      loading: false,
      isRequired: true,
      form: {
        type: 'ftp',
        format: null,
        // scheduler: null,
        selectedFtpFiles: []
      },
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats',
      'ftpChoices',
      'ftpChoicesLoading',
      'schedulers'
    ]),
    ...mapState('datasets', [
      'currentDatasetUsergroup'
    ]),

    isGeospatial() {
      return this.form.format && this.form.format.type.value === 'geospatial';
    }
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },
    form: {
      deep: true,
      handler(newValue) {
        if (newValue.format || newValue.selectedFtpFiles.length) {
          this.setRequired(true);
        } else {
          this.setRequired(false);
        }
      }
    },
    'form.selectedFtpFiles': {
      deep: true,
      handler: async function() {
        this.setResourceFTP();
      }
    },
    'form.format': function() {
      this.setResourceFTP();
    },
    ftpChoicesLoading(newValue) {
      this.loading = newValue;
    },
    isGeospatial(newValue) {
      this.$emit('geo', newValue);
    }
  },

  async created() {
    try {
      this.loading = true;
      this.isRequired = this.required;
      this.$emit('geo', this.isGeospatial);
      if (this.resourceDataFormats.length === 0) {
        this.GET_RESOURCE_DATA_FORMATS();
      }
      if (this.schedulers.length === 0) {
        this.GET_SCHEDULERS();
      }
      this.GET_FTP_CHOICES(this.currentDatasetUsergroup.id);
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  },

  methods: {
    ...mapActions('resources', [
      'GET_RESOURCE_DATA_FORMATS',
      'GET_SCHEDULERS',
      'GET_FTP_CHOICES'
    ]),

    setRequired(e) {
      this.isRequired = e;
      this.$emit('set', {
        required: this.isRequired
      });
    },

    onSelect (option) {
      option.checked = true;
    },
    
    onRemove (option) {
      option.checked = false;
    },

    setResourceFTP() {
      this.$emit('set', {
        form: this.form,
        required: this.isRequired
      });
    }
  }
};
</script>
