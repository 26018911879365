<template>
  <div :class="classes" class="control kinds-container">
    <div 
      v-for="kind of datasetsKinds"
      :key="kind.id"
      class="form-check"
    >
      <input
        :id="`check-kind-${kind.id}`"
        class="form-check-input"
        type="checkbox"
        :value="kind.id"
        v-model="selectedKinds"
      >
      <label class="form-check-label" :for="`check-kind-${kind.id}`">
        {{ kind.display_name }}
      </label>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Kinds',

  props: {
    kinds: {
      type: Array,
      default: () => {
        return [];
      }
    },
    classes: {
      type: Object,
      default: () => { 
        return {
          valid: 'is-valid',
          invalid: 'is-invalid'
        }
      }
    }
  },

  data() {
    return {
      selectedKinds: []
    };
  },

  computed: {
    ...mapState('datasets', [
      'datasetsKinds'
    ])
  },

  watch: {
    kinds: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.selectedKinds = newValue;
        }
      }
    },
    selectedKinds: {
      deep: true,
      handler(newValue) {
        this.$emit('update', newValue);
      }
    }
  },

  created() {
    this.selectedKinds = this.kinds;
  }
}
</script>

<style lang="less" scoped>
.kinds-container {
  margin: 0.5em 0;
  padding: 0 0.5em;
  border-radius: 5px;

  .form-check {
    margin: 0.5em 0;

    label {
      font-weight: 600;
    }
  }
}

.kinds-container.is-valid {
  border: 2px #078b40 solid;
}
.kinds-container.is-invalid {
  border: 2px #EB0600 solid;
}
</style>
