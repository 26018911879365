<template>
  <div>
    <h3 class="line-header">
      <img
        src="@/assets/icons/form_description_file.svg"
        alt="Icon form description file"
      > {{ $t('forms.datasets.title') }}
    </h3>

    <!-- NOM -->
    <div
      v-if="isFieldVisible('display_name', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label :class="isFieldRequired('display_name', formConfig.requiredFields)">
          {{ $t('forms.datasets.name.label') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Nom"
          :rules="isFieldRequired('display_name', formConfig.requiredFields)"
        >
          <input
            v-model="form.display_name"
            v-sanitize
            class="control form-control"
            :class="classes"
            type="text"
            :placeholder="$t('forms.datasets.name.placeholder')"
            maxlength="254"
          >
          <span class="form-errors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- DESCRIPTION -->
    <div
      v-if="isFieldVisible('description', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label :class="isFieldRequired('description', formConfig.requiredFields)">
          {{ $t('forms.datasets.description') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Description"
          :rules="isFieldRequired('description', formConfig.requiredFields)"
        >
          <MarkdownEditor
            :value="form.description"
            :classes="classes"
            @write="form.description = $event"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- MOT CLES -->
    <div
      v-if="isFieldVisible('keywords', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label :class="isFieldRequired('keywords', formConfig.requiredFields)">
          {{ $t('forms.datasets.keywords.label') }}
        </label>
        <p>{{ $t('forms.datasets.keywords.help') }}</p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Mot-clés"
          :rules="isFieldRequired('keywords', formConfig.requiredFields)"
        >
          <Keywords
            v-model="isKeywordsValid"
            :tags="[...form.keywords]"
            :classes="classes"
            @update="updateKeywords"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- CATEGORIES -->
    <div
      v-if="isFieldVisible('categories', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label
          :class="isFieldRequired('categories', formConfig.requiredFields)"
        >
          {{ $t('forms.datasets.categories.label') }} {{ clientName }}
        </label>
        <p>{{ $t('forms.datasets.categories.help') }}</p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Catégories"
          :rules="isFieldRequired('categories', formConfig.requiredFields)"
        >
          <Categories
            :classes="classes"
            :categories="datasetsCategories.map(el => el.display_name)"
            :dataset-categories="form.categories"
            @update="updateCategories"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- THEMATIQUES -->
    <div
      v-if="isFieldVisible('topics', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label :class="isFieldRequired('topics', formConfig.requiredFields)">
          {{ $t('forms.datasets.themes.label') }}
        </label>
        <p>{{ $t('forms.datasets.themes.help') }}</p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Thématiques"
          :rules="isFieldRequired('topics', formConfig.requiredFields)"
        >
          <Categories
            v-model="isTopicsValid"
            :categories="datasetsTopics.map(el => el.display_name)"
            :dataset-categories="form.topics"
            :classes="classes"
            @update="updateTopics"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- TYPES DE DONNEES -->
    <div
      v-if="isFieldVisible('kinds', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label :class="isFieldRequired('kinds', formConfig.requiredFields)">
          {{ $t('forms.datasets.kind.label') }}
        </label>
        <p>{{ $t('forms.datasets.kind.help') }}</p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          name="Types de données"
          :rules="isFieldRequired('kinds', formConfig.requiredFields)"
        >
          <Kinds
            v-model="isKindsValid"
            :kinds="form.kinds"
            :classes="classes"
            @update="updateKinds"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
    </div>

    <!-- PRECONISATION -->
    <div
      v-if="isFieldVisible('recommendation', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label :class="isFieldRequired('recommendation', formConfig.requiredFields)">
          {{ $t('forms.datasets.usage') }}
        </label>
        <textarea
          v-model="form.recommendation"
          v-sanitize
          class="form-control"
        />
      </div>
    </div>

    <!-- LIMITE D'UTILISATION -->
    <div
      v-if="isFieldVisible('restriction', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <label>{{ $t('forms.datasets.limit') }}</label>
        <Multiselect
          v-model="form.restriction"
          :options="datasetsRestrictions"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="true"
          :preserve-search="true"
          :show-no-results="false"
          :placeholder="$t('forms.datasets.limitPlaceholder')"
          @select="setRestriction"
          @search-change="setRestriction"
        />
      </div>
    </div>

    <h3
      v-if="isFieldVisible('usergroup', formConfig.hiddenFields) && mode === 'addDataset'"
      class="line-header"
    >
      <img
        src="@/assets/icons/company.svg"
        alt="Icon company"
      > {{ $t('forms.datasets.organisation.title') }}
    </h3>

    <!-- ORGANISATION -->
    <div
      v-if="isFieldVisible('usergroup', formConfig.hiddenFields) && mode === 'addDataset'"
      class="form-row"
    >
      <div class="form-group col-12">
        <label
          :class="isFieldRequired('usergroup', formConfig.requiredFields)"
          style="margin-top: 1em;"
        >
          {{ $t('forms.datasets.organisation.label') }}
        </label>
        <p>{{ $t('forms.datasets.organisation.help') }}</p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :rules="isFieldRequired('usergroup', formConfig.requiredFields)"
          name="Organisation"
        >
          <div
            class="control"
            :class="classes"
          >
            <SearchUsergroups
              v-model="form.usergroup"
              :types="['organisation', 'user-group']"
              :placeholder="$t('forms.datasets.organisation.help')"
              :usergroup="form.usergroup"
              :disabled="mode === 'datasetDetail'"
              :reset-after="false"
              :limit="100"
              :allow-null="true"
              @select="setOrganisation"
              @close="setOrganisation"
              @remove="setOrganisation"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>

    <h3 class="line-header">
      <img
        src="@/assets/icons/user-group.svg"
        alt="Icon company"
      > {{ $t('forms.datasets.contacts.title') }}
    </h3>

    <!-- CONTACTS -->
    <div
      v-if="isFieldVisible('contacts', formConfig.hiddenFields)"
      class="form-row"
    >
      <div class="form-group col-12">
        <p>
          {{ $t('forms.datasets.contacts.help') }}
        </p>
        <SearchContacts
          v-model="searchedContact"
          :reset-after="false"
          :limit="10"
          :current-contacts="form.datasetRoles.map(el => el.contact)"
          :placeholder="$t('forms.datasets.contacts.placeholder')"
          @select="addContact"
          @close="addContact"
        />
        <DatasetContactForm
          v-for="contact in form.datasetRoles"
          :key="`contact-${contact.order}-${contact.contact.name}`"
          :ref="`datasetContactForm`"
          :contact="contact"
          :current-contacts="currentDatasetRoles"
          :show-main="true"
          @set-main="setMainContact"
          @change="updateContact"
          @delete="askToRemoveContact"
        />
      </div>
    </div>
    <button
      class="add-button"
      @click.prevent="addContactForm"
    >
      <b-icon-plus />
      {{ $t('forms.datasets.contacts.add') }}
    </button>

    <h3 class="line-header">
      <img
        src="@/assets/icons/license_privacy_security.svg"
        alt="Icon license privacy security"
      > {{ $t('forms.datasets.licence.title') }}
    </h3>

    <!-- LICENCE -->
    <div class="form-row">
      <div class="form-group col-12">
        <label style="margin-top: 1em;">
          {{ $t('forms.datasets.licence.label') }}
        </label>
        <Multiselect
          v-model="form.licence"
          :options="datasetsLicences"
          track-by="codename"
          label="display_name"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="false"
          :placeholder="$t('forms.datasets.licence.label')"
        />
      </div>
    </div>

    <!-- COPYRIGHT -->
    <div class="form-row">
      <div class="form-group col-12">
        <label style="margin-top: 1em;">Copyright</label>
        <textarea
          v-model="form.copyright"
          v-sanitize
          class="form-control"
          type="text"
          rows="3"
          placeholder="Copyright"
        />
      </div>
    </div>

    <h3 class="line-header">
      <img
        src="@/assets/icons/territory_map_cadastre.svg"
        alt="Icon license privacy security"
      > {{ $t('forms.datasets.geography.title') }}
    </h3>

    <!-- GRANULARITE -->
    <div class="form-row">
      <div class="form-group col-12">
        <label style="margin-top: 1em;">
          {{ $t('forms.datasets.geography.granularity') }}
        </label>
        <Multiselect
          v-model="form.granularity"
          :options="datasetsGranularities"
          track-by="codename"
          label="display_name"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="false"
          :placeholder="$t('forms.datasets.geography.granularity')"
        />
      </div>
    </div>

    <!-- COUVERTURE GEOGRAPHIQUE -->
    <div class="form-row">
      <div class="form-group col-12">
        <label style="margin-top: 1em;">
          {{ $t('forms.datasets.geography.coverage.label') }}
        </label>
        <Multiselect
          v-model="form.coverage"
          :options="COVERAGE_OPTIONS"
          track-by="code"
          label="label"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="false"
          :placeholder="$t('forms.datasets.geography.coverage.placeholder')"
        />
      </div>
    </div>

    <h3 class="line-header">
      <img
        src="@/assets/icons/calendar_date.svg"
        alt="Icon files ressources"
      > {{ $t('forms.datasets.dates.title') }}
    </h3>

    <!-- DATES -->
    <div class="form-row">
      <div
        v-if="form.creationDate"
        class="form-group col-4"
      >
        <label
          class="required"
          style="margin-top: 1em;"
        >
          {{ $t('forms.datasets.dates.creationDate') }}
        </label>
        <div
          readonly
          class="form-control"
        >
          {{ new Date(form.creationDate).toLocaleString() }}
        </div>
      </div>
      <div
        v-if="form.revisionDate"
        class="form-group col-4"
      >
        <label
          class="required"
          style="margin-top: 1em;"
        >
          {{ $t('forms.datasets.dates.revisionDate') }}
        </label>
        <div
          readonly
          class="form-control"
        >
          {{ new Date(form.revisionDate).toLocaleString() }}
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-4">
        <label
          :class="isFieldRequired('publication_date', formConfig.requiredFields)"
          style="margin-top: 1em;"
        >
          {{ $t('forms.datasets.dates.publicationDate') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :rules="isFieldRequired('publication_date', formConfig.requiredFields)"
          name="Date de publication"
        >
          <div
            class="control"
            :class="classes"
          >
            <input
              v-model="form.publicationDate"
              class="form-control"
              type="date"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="form-group col-4">
        <label
          :class="isFieldRequired('lastUpdateDate', formConfig.requiredFields)"
          style="margin-top: 1em;"
        >
          {{ $t('forms.datasets.dates.lastUpdateDate') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :rules="isFieldRequired('lastUpdateDate', formConfig.requiredFields)"
          name="Date de dernière mise à jour"
        >
          <div
            class="control"
            :class="classes"
          >
            <input
              v-model="form.lastUpdateDate"
              class="form-control"
              type="date"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>

    <!-- FREQUENCE -->
    <div class="form-row">
      <div class="form-group col-5">
        <label
          :class="isFieldRequired('update_frequency', formConfig.requiredFields)"
          style="margin-top: 1em;"
        >
          {{ $t('forms.datasets.dates.frequency.label') }}
        </label>
        <p class="label-help">
          {{ $t('forms.datasets.dates.frequency.help') }}
        </p>
        <ValidationProvider
          v-slot="{ classes, errors }"
          :rules="isFieldRequired('update_frequency', formConfig.requiredFields)"
          name="Fréquence"
        >
          <div
            class="control"
            :class="classes"
          >
            <Multiselect
              v-model="form.frequence"
              :options="datasetsUpdateFrequencies"
              track-by="value"
              label="label"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('forms.datasets.dates.frequency.label')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="form-group col-3">
        <label style="margin-top: 1em;">
          {{ $t('forms.datasets.dates.year.label') }}
        </label>
        <p class="label-help">
          {{ $t('forms.datasets.dates.year.help') }}
        </p>
        <input
          v-model="form.year"
          v-sanitize
          class="form-control"
          :placeholder="$t('forms.datasets.dates.year.label')"
          type="number"
          :min="1900"
          :max="new Date().getFullYear()"
          step="1"
        >
      </div>
    </div>

    <!-- MODE DE CONSTITUTION -->
    <div class="form-row">
      <div class="form-group col">
        <label style="margin-top: 1em;">
          {{ $t('forms.datasets.dates.mode') }}
        </label>
        <textarea
          v-model="form.lineage"
          v-sanitize
          class="form-control"
        />
      </div>
    </div>

    <!-- DATAVISUALISATION -->
    <div
      v-for="extraField of formConfig.extraFields"
      :key="extraField.title.en"
    >
      <h3 class="line-header">
        <img
          v-if="extraField.icon"
          :src="getIconPath(extraField.icon)"
          :alt="`Icon ${extraField.icon}`"
        > {{ extraField.title[$i18n.locale] }}
      </h3>
      <div
        v-for="field of extraField.fields"
        :key="field.key"
        class="form-row"
      >
        <div class="form-group col">
          <label>
            {{ field.label[$i18n.locale] }}
          </label>
          <p class="label-help">
            {{ field.help[$i18n.locale] }}
          </p>
          <input
            v-model="form[field.key]"
            v-sanitize
            class="form-control"
            placeholder="https://"
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Keywords from '@/components/Keywords.vue';
import Categories from '@/components/Categories.vue';
import Kinds from '@/components/Kinds.vue';
import DatasetContactForm from '@/components/Forms/DatasetContactForm.vue';
import MarkdownEditor from '@/components/Elements/MarkdownEditor.vue';
import SearchContacts from '@/components/Searchbars/SearchContacts.vue';
import SearchUsergroups from '@/components/Searchbars/SearchUsergroups.vue';
import Multiselect from 'vue-multiselect';

import { mapState, mapMutations, mapActions } from 'vuex';

import { COVERAGE_OPTIONS } from '@/constants.js';

import { ValidationProvider, extend, configure, setInteractionMode } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});
setInteractionMode('passive');

export default {
  name: 'DatasetForm',

  components: {
    Keywords,
    Categories,
    Kinds,
    DatasetContactForm,
    MarkdownEditor,
    SearchContacts,
    SearchUsergroups,
    Multiselect,
    ValidationProvider
  },

  props: {
    mode: {
      type: String,
      default: 'datasetDetail'
    },
    defaultForm: {
      type: Object,
      default: () => {
        return {
          display_name: null,
          description: null,
          keywords: [],
          categories: [],
          topics: [],
          kinds: [],
          recommendation: null,
          restriction: null,
          usergroup: null,
          datasetRoles: [],
          licence: null,
          copyright: null,
          granularity: null,
          coverage: null,
          frequence: null,
          publicationDate: null,
          lastUpdateDate: null,
          creationDate: null,
          revisionDate: null,
          year: null,
          lineage: null,
          resource: null,
          annexe: null,
          thumbnail: null
        };
      }
    },
    usergroup: {
      type: Object,
      default: () => { return null; }
    }
  },

  data() {
    return {
      form: {},
      searchedContact: null,
      COVERAGE_OPTIONS: COVERAGE_OPTIONS,
      isKeywordsValid: null,
      isTopicsValid: null,
      isKindsValid: null,
      contactToRemoveIndex: null,
    };
  },

  computed: {
    ...mapState('user', [
      'userData'
    ]),
    ...mapState('form', [
      'currentFormId',
      'currentForm'
    ]),
    ...mapState('user-login', [
      'userData'
    ]),
    ...mapState('datasets', [
      'currentDataset',
      'datasetsCategories',
      'datasetsGranularities',
      'datasetsLicences',
      'datasetsTopics',
      'datasetsKinds',
      'datasetsRestrictions',
      'datasetsRoles',
      'currentDatasetRoles',
      'datasetsUpdateFrequencies',
      'lastCreatedDataset'
    ]),
    ...mapState('resources', [
      'resourceInspectionData',
    ]),

    formConfig() {
      return this.$config.forms.dataset;
    },

    clientName() {
      return process.env.VUE_APP_CLIENT_NAME;
    }
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        if (newValue.keywords.length > 0) {
          this.isKeywordsValid = true;
        } else {
          this.isKeywordsValid = null;
        }
        this.SET_CURRENT_FORM({
          id: this.mode,
          form: newValue
        });
        this.$emit('change', newValue);
      }
    },

    'form.resource.file': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.SET_CURRENT_RESOURCE_ID(null);
      }
    },

    usergroup: {
      deep: true,
      handler(newValue) {
        this.setOrganisation(newValue);
      }
    }
  },

  created() {
    if (this.form.datasetRoles && this.form.datasetRoles.length) {
      this.form.datasetsRoles.splice(0);
    }
    this.getConfigData();
    if (this.defaultForm) {
      this.form = this.defaultForm;
      if (this.form.keywords.length > 0) {
        this.isKeywordsValid = true;
      } else {
        this.isKeywordsValid = null;
      }
    }
    // Add extra fields
    for (const extraField of this.formConfig.extraFields) {
      for (const field of extraField.fields) {
        this.form[field.key] = null;
      }
    }

    if (this.currentFormId === this.mode) {
      this.form = {
        ...this.form,
        ...this.currentForm,
      };
      this.form.resource = null;
      this.SET_CURRENT_RESOURCE_ID(null);
    }
  },

  methods: {
    ...mapMutations('form', [
      'SET_CURRENT_FORM'
    ]),
    ...mapMutations('resources', [
      'SET_CURRENT_RESOURCE_ID'
    ]),
    ...mapActions('datasets', [
      'UPDATE_CURRENT_DATASET_USERGROUP',
      'GET_DATASETS_CATEGORIES',
      'GET_DATASETS_GRANULARITIES',
      'GET_DATASETS_LICENCES',
      'GET_DATASETS_TOPICS',
      'GET_DATASETS_KINDS',
      'GET_DATASETS_RESTRICTIONS',
      'GET_DATASETS_UPDATE_FREQUENCIES',
      'GET_DATASETS_ROLES'
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUPS_ROLES'
    ]),

    getConfigData() {
      if (this.datasetsCategories.length === 0) {
        this.GET_DATASETS_CATEGORIES();
      }
      if (this.datasetsGranularities.length === 0) {
        this.GET_DATASETS_GRANULARITIES();
      }
      if (this.datasetsLicences.length === 0) {
        this.GET_DATASETS_LICENCES();
      }
      if (this.datasetsTopics.length === 0) {
        this.GET_DATASETS_TOPICS();
      }
      if (this.datasetsKinds.length === 0) {
        this.GET_DATASETS_KINDS();
      }
      if (this.datasetsRestrictions.length === 0) {
        this.GET_DATASETS_RESTRICTIONS();
      }
      if (this.datasetsUpdateFrequencies.length === 0) {
        this.GET_DATASETS_UPDATE_FREQUENCIES();
      }
      if (this.datasetsRoles.length === 0) {
        this.GET_DATASETS_ROLES();
      }
    },

    setRestriction(e) {
      this.form.restriction = e;
    },

    setOrganisation(e) {
      if (e !== this.form.usergroup) {
        this.UPDATE_CURRENT_DATASET_USERGROUP(e);
      }
      this.form.usergroup = e;
    },

    updateCategories(e) {
      this.form.categories = e;
    },

    updateTopics(e) {
      this.form.topics = e;
      if (this.form.topics.length > 0) {
        this.isTopicsValid = true;
      } else {
        this.isTopicsValid = null;
      }
    },

    updateKinds(e) {
      this.form.kinds = e;
      if (this.form.kinds.length > 0) {
        this.isKindsValid = true;
      } else {
        this.isKindsValid = null;
      }
    },

    updateKeywords(e) {
      this.form.keywords = e;
      if (this.form.keywords.length > 0) {
        this.isKeywordsValid = true;
      } else {
        this.isKeywordsValid = null;
      }
    },

    async addContact(e) {
      const order = this.form.datasetRoles.length + 1;

      this.form.datasetRoles.push({
        order: order,
        id: null,
        role: e.role,
        main: order === 1 ? true : false,
        contact: {
          id: e.id,
          email: e.email,
          name: e.name,
          phone_number: e.phone_number,
          position: e.position,
          usergroup: e.usergroup
        }
      });
      
      if (this.form.datasetRoles.find(el => {
        return el.email === null &&
          el.name === '' &&
          el.phone_number === null &&
          el.role === null &&
          el.usergroup === null;
      })) {
        const index = this.form.datasetRoles.findIndex(el => {
          return el.email === null &&
            el.name === '' &&
            el.phone_number === null &&
            el.role === null &&
            el.usergroup === null;
        });
        this.form.datasetRoles.splice(index, 1);
      }
    },

    addContactForm() {
      const order = this.form.datasetRoles.length + 1;
      this.form.datasetRoles.push({
        order: order,
        id: null,
        role: null,
        main: order === 1 ? true : false,
        contact: {
          id: null,
          email: null,
          name: '',
          position: null,
          phone_number: null,
          usergroup: null
        }
      });
    },

    updateContact(e) {
      const index = this.form.datasetRoles.findIndex(el => el.order === e.order);
      this.form.datasetRoles[index] = {
        id: e.id,
        order: e.order,
        role: e.role && e.role.value ? e.role.value : e.role,
        main: e.main,
        contact: {
          id: e.contact.id,
          email: e.contact.email,
          name: e.contact.name,
          phone_number: e.contact.phone_number,
          position: e.contact.position,
          usergroup: e.contact.usergroup ? e.contact.usergroup.id : e.contact.usergroup
        }
      };
      this.$emit('change', this.form);
    },

    askToRemoveContact(e) {
      const currentContact = this.form.datasetRoles.find(el => el.order === e);
      this.contactToRemoveIndex = this.form.datasetRoles.findIndex(el => el.order === e);
      if (currentContact.id) {
        this.$emit('remove-contact', this.contactToRemoveIndex);
      } else {
        this.form.datasetRoles.splice(this.contactToRemoveIndex, 1);
        this.form.datasetRoles.forEach((el, i) => el.order = i + 1);
        this.checkIfMainContactExist();
      }
    },

    setMainContact(e) {
      for (const contactForm of this.$refs['datasetContactForm']) {
        contactForm.unsetMainContact(e);
      }
      this.checkIfMainContactExist();
      this.$emit('change', this.form);
    },
    checkIfMainContactExist() {
      if (this.$refs['datasetContactForm'].every(el => {
        return el.formData.main === false;
      })) {
        this.$refs['datasetContactForm'][0].formData.main = true;
      } 
    }
  }
};
</script>
