<template>
  <div>
    <ValidationProvider
      v-slot="{ classes, errors }"
      :name="`Datastore table ${usedFor}`"
      :rules="`${isRequired ? 'required' : ''}`"
    >
      <div
        class="control"
        :class="isRequired ? classes : ''"
      >
        <Multiselect
          v-model="selection"
          style="margin-top: 0.5em;"
          class="search-usergroups"
          :options="results"
          :options-limit="10"
          :allow-empty="true"
          track-by="table_name"
          label="label"
          :reset-after="false"
          select-label=""
          selected-label=""
          deselect-label=""
          :searchable="true"
          :placeholder="placeholder"
          :show-no-results="true"
          :loading="isLoading"
          :clear-on-select="false"
          :preserve-search="false"
          :disabled="disabled && !text"
          @search-change="search"
          @close="select"
        >
          <template slot="clear">
            <div
              v-if="selection"
              class="multiselect__clear"
              @click.prevent.stop="selection = null"
            >
              <b-icon-x font-scale="2" />
            </div>
          </template>
          <span slot="noResult">
            {{ $t('searchbars.noResult') }}
          </span>
          <span slot="noOptions">
            {{ $t('searchbars.noOptions') }}
          </span>
        </Multiselect>
        <span class="form-errors">{{ errors[0] }}</span>
      </div>
    </ValidationProvider>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'SearchDatastoreTables',

  components: {
    Multiselect,
    ValidationProvider
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },
    table: {
      type: Object,
      default: () => { return {}; }
    },
    disabled: {
      type: Boolean,
      default: true
    },
    required: {
      type: Boolean,
      default: false
    },
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Rechercher une table ...'
    },
    usedFor: {
      type: String,
      default: 'resource'
    }
  },

  data() {
    return {
      selection: null,
      isLoading: false,
      text: null,
      results: [],
      isRequired: false
    };
  },

  computed: {
    ...mapState('datastore', [
      'datastoreTables'
    ])
  },

  watch: {
    loading(newValue) {
      this.isLoading = newValue;
    },
    disabled(newValue) {
      if (!newValue) {
        this.results = this.datastoreTables;
      }
    },
    required(newValue) {
      this.isRequired = newValue;
    },

    table: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selection = newValue;
        }
      }
    },

    text: function(newValue) {
      this.isLoading = true;
      this.SEARCH_DATASTORE_TABLES(newValue)
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },

    datastoreTables: {
      deep: true,
      handler(newValue) {
        this.results = newValue;
      }
    }
  },

  created() {
    if (this.table) {
      this.selection = this.table;
    }
    this.results = this.datastoreTables;
    this.isRequired = this.required;
  },

  methods: {
    ...mapActions('datastore', [
      'SEARCH_DATASTORE_TABLES'
    ]),

    search(text) {
      this.text = text;
    },

    select() {
      this.$emit('select', this.selection);
    },

    clear() {
      this.selection = null;
    }
  }
};
</script>
