var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-row"},[_c('b-overlay',{staticStyle:{"z-index":"1040"},attrs:{"show":_vm.loading,"variant":'white',"opacity":0.7,"blur":'2px',"rounded":"sm","no-wrap":""}}),_c('div',{staticClass:"form-group col-6"},[_c('label',{staticClass:"required",staticStyle:{"margin-top":"1em"}},[_vm._v(" "+_vm._s(_vm.$tc('resource.geoserver.workspace.label', 1))+" ")]),_c('ValidationProvider',{attrs:{"name":("Layer workspace " + _vm.usedFor),"rules":("" + (_vm.isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('Multiselect',{staticClass:"search-usergroups",staticStyle:{"margin-top":"0.5em"},attrs:{"options":_vm.workspaces,"allow-empty":true,"track-by":"name","label":"name","reset-after":false,"select-label":"","selected-label":"","deselect-label":"","searchable":true,"placeholder":_vm.$t('resource.geoserver.workspace.placeholder'),"loading":_vm.workspacesLoading,"disabled":_vm.workspacesLoading,"clear-on-select":false},on:{"select":_vm.selectWorkspace},model:{value:(_vm.form.workspace),callback:function ($$v) {_vm.$set(_vm.form, "workspace", $$v)},expression:"form.workspace"}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1),(_vm.form.workspace)?_c('div',{staticClass:"form-group col-6 layers-layergroups"},[_c('b-form-group',[_c('b-form-radio-group',{attrs:{"id":"btn-radios","options":[
            { text: _vm.$tc('resource.geoserver.layer.label', 2), value: 'restlayers' },
            { text: _vm.$tc('resource.geoserver.layergroup.label', 2), value: 'restlayergroups' }
          ],"button-variant":"outline-primary","name":"radio-btn-outline","buttons":""},model:{value:(_vm.layersORlayergroups),callback:function ($$v) {_vm.layersORlayergroups=$$v},expression:"layersORlayergroups"}})],1)],1):_vm._e()],1),(_vm.form.workspace)?_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-6",class:{ disabled: _vm.layersORlayergroups !== 'restlayers'}},[_c('label',{class:{ required: _vm.layersORlayergroups === 'restlayers'},staticStyle:{"margin-top":"1em"}},[_vm._v(" "+_vm._s(_vm.$tc('resource.geoserver.layer.label', 1))+" ")]),_c('ValidationProvider',{attrs:{"name":("Layer layer " + _vm.usedFor),"rules":("" + (_vm.isRequired && _vm.layersORlayergroups === 'layers' ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var classes = ref.classes;
          var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('Multiselect',{staticClass:"search-usergroups",staticStyle:{"margin-top":"0.5em"},attrs:{"options":_vm.layers,"allow-empty":true,"track-by":"name","label":"name","reset-after":false,"select-label":"","selected-label":"","deselect-label":"","searchable":true,"placeholder":_vm.$t('resource.geoserver.layer.placeholder'),"loading":_vm.layersLoading,"disabled":_vm.layersLoading,"clear-on-select":false},on:{"select":_vm.selectLayer},model:{value:(_vm.form.layer),callback:function ($$v) {_vm.$set(_vm.form, "layer", $$v)},expression:"form.layer"}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3592874916)})],1),_c('div',{staticClass:"form-group col-6",class:{ disabled: _vm.layersORlayergroups !== 'restlayergroups'}},[_c('label',{class:{ required: _vm.layersORlayergroups === 'restlayergroups'},staticStyle:{"margin-top":"1em"}},[_vm._v(" "+_vm._s(_vm.$tc('resource.geoserver.layergroup.label', 1))+" ")]),_c('ValidationProvider',{attrs:{"name":("Layer layergroup " + _vm.usedFor),"rules":("" + (_vm.layersORlayergroups === 'restlayergroups' && _vm.isRequired ? 'required' : ''))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var classes = ref.classes;
          var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('Multiselect',{staticClass:"search-usergroups",staticStyle:{"margin-top":"0.5em"},attrs:{"options":_vm.layergroups,"allow-empty":true,"track-by":"name","label":"name","reset-after":false,"select-label":"","selected-label":"","deselect-label":"","searchable":true,"placeholder":_vm.$t('resource.geoserver.layergroup.placeholder'),"loading":_vm.layergroupsLoading,"disabled":_vm.layergroupsLoading,"clear-on-select":false},on:{"select":_vm.selectLayergroup},model:{value:(_vm.form.layergroup),callback:function ($$v) {_vm.$set(_vm.form, "layergroup", $$v)},expression:"form.layergroup"}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3348979108)})],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }