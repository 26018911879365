<template>
  <div class="resource_geographical_layer">
    <div class="form-row">
      <div class="form-group col-12">
        <label :class="isFieldRequired('geographiclayer__display_name', formConfig.requiredFields) ? 'required' : ''">
          {{ $t('resource.geographicalDisplayName') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          ref="geographical_display_label"
          :rules="isFieldRequired('geographiclayer__display_name', formConfig.requiredFields)"
          :name="$t('resource.geographicalDisplayName')"
        >
          <div
            class="control"
            :class="classes"
          >
            <input
              v-model="form.geographiclayerDisplayName"
              v-sanitize
              class="form-control"
              type="text"
              :placeholder="$t('resource.geographicalDisplayName')"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
    <div class="form-row">
      <div
        :class="{ disabled: codenameDisabled }"
        class="form-group col-12"
      >
        <label :class="isFieldRequired('geographiclayer__codename', formConfig.requiredFields) ? 'required' : ''">
          {{ $t('resource.geographicalCodename') }}
        </label>
        <ValidationProvider
          v-slot="{ classes, errors }"
          ref="geographical_display_label"
          :rules="isFieldRequired('geographiclayer__codename', formConfig.requiredFields)"
          :name="$t('resource.geographicalCodename')"
        >
          <div
            class="control"
            :class="classes"
          >
            <input
              v-model="form.geographiclayerCodename"
              v-sanitize
              class="form-control"
              type="text"
              :placeholder="$t('resource.geographicalCodename')"
              :tabindex="codenameDisabled ? '-1' : ''"
            >
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});


export default {
  name: 'ResourceGeographicalLayer',

  components: {
    ValidationProvider
  },

  props: {
    formConfig: {
      type: Object,
      default: () => {}
    },
    geoData: {
      type: Object,
      default: () => {
        return {
          geographiclayerDisplayName: null,
          geographiclayerCodename: null
        };
      }
    },
    codenameDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: {
        geographiclayerDisplayName: null,
        geographiclayerCodename: null
      }
    };
  },

  watch: {
    form: {
      deep: true,
      handler(newValue) {
        this.setForm(newValue);
      }
    }
  },

  created() {
    this.setForm(this.geoData);
  },
  
  methods: {
    setForm(formData) {
      this.form.geographiclayerDisplayName = formData.geographiclayerDisplayName;
      this.form.geographiclayerCodename = formData.geographiclayerCodename;
      this.$emit('set', {
        form: this.form,
      });
    }
  }

};
</script>