<template>
  <div :class="classes" class="control categories-container">
    <div
      v-for="category of categories"
      :key="category"
      class="category"
      :class="selectedCategories.find(el => el === category) ? 'active' : ''"
      @click="selectTopic(category)"
    >
      {{ category }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'Categories',

  props: {
    categories: {
      type: Array,
      default: () => [
        'Population', 'Réseaux', 'Logement', 'Services',
        'Santé', 'Education', 'Environnement', 'Economie',
        'Politique', 'Limites administratives', 'Transports',
        'Culture', 'Urbanisme', 'Agriculture', 'Equipements',
        'Accessibilité'
      ]
    },
    datasetCategories: {
      type: Array,
      default: () => { return [] }
    },
    classes: {
      type: Object,
      default: () => { 
        return {
          valid: 'is-valid',
          invalid: 'is-invalid'
        }
      }
    }
  },

  data() {
    return {
      selectedCategories: []
    }
  },

  created() {
    this.selectedCategories = this.datasetCategories;
  },

  watch: {
    datasetCategories: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.selectedCategories = newValue;
        }
      }
    },
    selectedCategories: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          this.$emit('update', newValue);
        }
      }
    }
  },

  methods: {
    selectTopic(category) {
      const index = this.selectedCategories.findIndex(el => el === category);
      if (index !== -1) {
        this.selectedCategories.splice(index, 1);
      } else {
        this.selectedCategories.push(category);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.categories-container {
  display: flex;
  flex-flow: row wrap;
  padding: 0.1em;
  margin: 0.5em;
  border-radius: 5px;

  .category {
    cursor: pointer;
    margin: 0.2em 1em 0.2em 0;
    padding: 0.5em;
    border-radius: 10px;
    font-size: 0.9em;
  }
  .active {
    background-color: @blue;
    color: white;
  }
}

.categories-container.is-valid {
  border: 2px #078b40 solid;
}
.categories-container.is-invalid {
  border: 2px #EB0600 solid;
}
</style>
