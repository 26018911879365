<template>
  <div>
    <b-overlay
      id="overlay-background"
      :show="loading"
      :variant="'white'"
      :opacity="0.7"
      :blur="'2px'"
      rounded="sm"
      no-wrap
    />
    <div
      v-if="!format"
      class="form-group col-6"
    >
      <label
        class="required"
        style="margin-top: 1em;"
      >
        {{ $t('resource.datastore.format.label') }}
      </label>
      <ValidationProvider
        v-slot="{ classes, errors }"
        :name="`Datastore format ${usedFor}`"
        :rules="`${isRequired ? 'required' : ''}`"
      >
        <div
          class="control"
          :class="classes"
        >
          <Multiselect
            v-model="form.format"
            :options="formatOptions"
            :class="isRequired ? classes : ''"
            track-by="value"
            label="label"
            select-label=""
            selected-label=""
            deselect-label=""
            :searchable="false"
            :placeholder="$t('resource.datastore.format.placeholder')"
            @select="selectFormat"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
    </div>
    <div class="form-group col-12">
      <label
        class="required"
        style="margin-top: 1em;"
      >
        {{ $t('resource.datastore.database.label') }}
      </label>
      <ValidationProvider
        v-slot="{ classes, errors }"
        :name="`Datastore name ${usedFor}`"
        :rules="`${isRequired ? 'required' : ''}`"
      >
        <div
          class="control"
          :class="classes"
        >
          <Multiselect
            v-model="form.namespace"
            :loading="datastoreLoading"
            :options="datastoresList"
            :class="isRequired ? classes : ''"
            :disabled="!(form.format && datastoresList.length)"
            track-by="url"
            label="label"
            select-label=""
            selected-label=""
            deselect-label=""
            :preselect-first="false"
            :allow-empty="true"
            :searchable="false"
            :placeholder="$t('resource.datastore.database.placeholder')"
            @select="selectDatastore"
          />
          <span class="form-errors">{{ errors[0] }}</span>
        </div>
      </ValidationProvider>
    </div>
    <!-- TABLE -->
    <div class="form-group col-12">
      <label
        class="required"
        style="margin-top: 1em;"
      >
        {{ $t('resource.datastore.table.label') }}
      </label>
      <SearchDatastoreTables
        :loading="tableLoading"
        :table="selectedTable"
        :disabled="!(form.namespace && datastoreTables.length)"
        :required="isRequired"
        :used-for="usedFor"
        :placeholder="$t('resource.datastore.table.placeholder')"
        @select="selectDatastoreTable"
      />
      <p class="label-help">
        {{ $t('resource.datastore.table.help') }}
      </p>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';
import SearchDatastoreTables from '@/components/Searchbars/SearchDatastoreTables';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'DatastoreCreator',

  components: {
    SearchDatastoreTables,
    Multiselect,
    ValidationProvider
  },

  props: {
    required: {
      type: Boolean,
      default: true
    },
    usedFor: {
      type: String,
      default: 'resource'
    },
    format: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      loading: false,
      datastoreLoading: false,
      tableLoading: false,
      isRequired: true,
      form: {
        type: 'datastore',
        format: null,
        namespace: null,
        table_name: null,
        schema_name: null,
      },
      selectedTable: null,
      formatOptions: [
        { value: 'postgres', label: 'PostgreSQL', type: 'datapusher' },
        { value: 'postgis', label: 'PostGIS', type: 'geospatial' }
      ],
      schemaOptions: [],
      tableOptions: [],
    };
  },

  computed: {
    ...mapState('datastore', [
      'datastoresList',
      'datastoreTables'
    ]),

    isGeospatial() {
      return this.form.format && this.form.format.value === 'postgis';
    }
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },

    'form.format.value': function(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.selectedTable = null;
        this.form.table_name = null;
        this.form.namespace = null;
      }
    },

    format(newValue) {
      if (newValue) {
        this.form.format = this.formatOptions.find(el => el.value === newValue);
        this.selectFormat(this.form.format);
      }
    },

    form: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.format.value !== oldValue.format.value) {
          this.form.namespace = null;
        }
        if (newValue.format) {
          this.isRequired = true;
        } else {
          this.isRequired = false;
        }
        this.$emit('set', {
          displayName: newValue.schema_name && newValue.table_name ? `${newValue.schema_name}.${newValue.table_name}` : null,
          form: {
            ...newValue,
            namespace: newValue.namespace && newValue.namespace.label ? newValue.namespace.label : null
          },
          required: this.isRequired
        });
      }
    },

    isGeospatial(newValue) {
      this.$emit('geo', newValue);
    }
  },

  created() {
    this.isRequired = this.required;
    this.$emit('geo', this.isGeospatial);
    if (this.format) {
      this.form.format = this.formatOptions.find(el => el.value === this.format);
      this.selectFormat(this.form.format);
    }
  },

  methods: {
    ...mapMutations('datastore', [
      'SET_SELECTED_DATASTORE'
    ]),
    ...mapActions('datastore', [
      'GET_DATASTORES_LIST',
      'GET_DATASTORE_TABLES'
    ]),

    setRequired(e) {
      this.isRequired = e;
    },

    selectFormat(e) {
      if (e.type) {
        this.datastoreLoading = true;
        this.GET_DATASTORES_LIST(e.type)
          .then(() => {
            if (this.datastoresList && this.datastoresList.length) {
              this.form.namespace = this.datastoresList[0];
              this.selectDatastore(this.datastoresList[0]);
            }
            this.datastoreLoading = false;
          })
          .catch(() => {
            this.datastoreLoading = false;
          });
      }
    },

    selectDatastore(e) {
      this.SET_SELECTED_DATASTORE(e);
      if (e.url) {
        this.tableLoading = true;
        this.GET_DATASTORE_TABLES(e.url)
          .then(() => {
            this.tableLoading = false;
          })
          .catch((err) => {
            console.error(err);
            this.tableLoading = false;
          });
      }
    },

    selectDatastoreTable(e) {
      this.selectedTable = e;
      if (e) {
        this.form.schema_name = e.schema_name;
        this.form.table_name = e.table_name;
      } else {
        this.form.schema_name = null;
        this.form.table_name = null;
      }
    }
  }
};
</script>
