var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"resource_meta_data"},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{class:_vm.isFieldRequired('display_name', _vm.formConfig.requiredFields)},[_vm._v(" "+_vm._s(_vm.$t('resource.name.label'))+" ")]),_c('ValidationProvider',{ref:"display_name",attrs:{"rules":_vm.isRequired ? _vm.isFieldRequired('display_name', _vm.formConfig.requiredFields) : '',"name":("Nom " + (_vm.isMain ? 'ressource' : 'annexe'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.displayName),expression:"form.displayName"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.isMain ?
              _vm.$t('resource.name.resourcePlaceholder') : _vm.$t('resource.name.annexPlaceholder')},domProps:{"value":(_vm.form.displayName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "displayName", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v(" "+_vm._s(_vm.$t('resource.description'))+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.description),expression:"form.description"},{name:"sanitize",rawName:"v-sanitize"}],staticClass:"form-control",domProps:{"value":(_vm.form.description)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "description", $event.target.value)}}})])]),_c('div',{staticClass:"form-row"},[(_vm.isFieldVisible('kind', _vm.formConfig.hiddenFields))?_c('div',{staticClass:"form-group col-6"},[_c('label',{class:_vm.isRequired ? _vm.isFieldRequired('kind', _vm.formConfig.requiredFields) : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.type.label'))+" ")]),_c('ValidationProvider',{ref:"kind",attrs:{"rules":_vm.isRequired ? _vm.isFieldRequired('kind', _vm.formConfig.requiredFields) : '',"name":"Type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var classes = ref.classes;
              var errors = ref.errors;
return [_c('div',{staticClass:"control",class:classes},[_c('Multiselect',{class:classes,attrs:{"options":_vm.resourceKinds,"track-by":"id","label":"display_name","select-label":"","selected-label":"","deselect-label":"","searchable":false,"placeholder":_vm.$t('resource.type.placeholder')},model:{value:(_vm.form.kind),callback:function ($$v) {_vm.$set(_vm.form, "kind", $$v)},expression:"form.kind"}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3963970180)})],1):_vm._e(),_c('div',{staticClass:"form-group col-6"},[_c('label',{class:_vm.isMain ? _vm.isFieldRequired('lastUpdateDate', _vm.formConfig.requiredFields) : ''},[_vm._v(" "+_vm._s(_vm.$t('resource.lastUpdateDate'))+" ")]),_c('ValidationProvider',{ref:"lastUpdateDate",attrs:{"rules":_vm.isMain ? _vm.isFieldRequired('lastUpdateDate', _vm.formConfig.requiredFields) : '',"name":("Date de dernière mise à jour " + (_vm.isMain ? 'ressource' : 'annexe'))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var classes = ref.classes;
              var errors = ref.errors;
return [_c('div',{staticClass:"control",class:_vm.isMain ? classes : ''},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastUpdateDate),expression:"form.lastUpdateDate"}],staticClass:"form-control",class:{ empty: !_vm.form.lastUpdateDate},attrs:{"type":"date"},domProps:{"value":(_vm.form.lastUpdateDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastUpdateDate", $event.target.value)}}}),_c('span',{staticClass:"form-errors"},[_vm._v(_vm._s(errors[0]))])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }