<template>
  <div>
    <div class="form-row">
      <div class="form-group col-6">
        <label 
          class="required"
          style="margin-top: 1em;"
        >
          {{ $t('resource.format.label') }}
        </label>
        <p>{{ $t('resource.format.help') }}</p>
        <ValidationProvider
          id="file-upload-format"
          v-slot="{ classes, errors }"
          :name="`Format ${usedFor}`"
          :rules="`${isRequired ? 'required' : ''}`"
        >
          <div
            class="control"
            :class="isRequired ? classes : ''"
          >
            <Multiselect
              v-model="form.format"
              :options="resourceDataFormats"
              :class="isRequired ? classes : ''"
              track-by="codename"
              label="display_name"
              select-label=""
              selected-label=""
              deselect-label=""
              :searchable="false"
              :placeholder="$t('resource.format.placeholder')"
            />
            <span class="form-errors">{{ errors[0] }}</span>
          </div>
        </ValidationProvider>
      </div>
      <div class="col-1" />
      <div class="form-group col-5">
        <label>
          {{ $t('resource.fileUpload.file.label') }}
        </label>
        <p>{{ $t('resource.fileUpload.file.help') }}</p>
        <ImportFile
          :key="`${usedFor}-import-file`"
          :name="usedFor"
          :accept="form.format ?
            resourceDataFormats.find(el => el.codename === form.format.codename).extension.map(el => { return `.${el}` }).join(',') :
            ''
          "
          :disabled="!(form.format && form.format.codename)"
          :required="isRequired"
          @update="setResourceFile"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';
import ImportFile from '@/components/ImportFile.vue';

import { ValidationProvider, extend, configure } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champ est requis'
});
configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
});

export default {
  name: 'FileUploadCreator',

  components: {
    ImportFile,
    ValidationProvider,
    Multiselect
  },

  props: {
    required: {
      type: Boolean,
      default: true
    },
    usedFor: {
      type: String,
      default: 'resource'
    },
  },

  data() {
    return {
      isRequired: true,
      form: {
        type: 'file-upload',
        format: null,
        file: null
      },
    };
  },

  computed: {
    ...mapState('resources', [
      'resourceDataFormats',
    ]),

    isGeospatial() {
      return this.form.format && this.form.format.type.value === 'geospatial';
    }
  },

  watch: {
    required(newValue) {
      this.isRequired = newValue;
    },
    form: {
      deep: true,
      handler(newValue) {
        if (newValue.format || newValue.file) {
          this.isRequired = true;
        } else {
          this.isRequired = false;
        }
        this.$emit('set', {
          required: this.isRequired,
          form: newValue
        });
      }
    },
    isGeospatial(newValue) {
      this.$emit('geo', newValue);
    }
  },

  created() {
    this.isRequired = this.required;
    this.$emit('geo', this.isGeospatial);
    if (this.resourceDataFormats.length === 0) {
      this.GET_RESOURCE_DATA_FORMATS();
    }
  },

  methods: {
    ...mapActions('resources', [
      'GET_RESOURCE_DATA_FORMATS'
    ]),
    setResourceFile(e) {
      const formData = new FormData();
      formData.append('file', e);
      this.form.file = formData;
      this.$emit('set', {
        form: this.form,
        required: this.isRequired
      });
    }
  }
};
</script>
