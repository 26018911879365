<template>
  <div :class="disabled ? 'disabled' : ''">
    <Multiselect
      ref="multiselect"
      v-model="selection"
      style="margin-top: 0.5em;"
      class="search-usergroups"
      :options="results"
      :options-limit="limit"
      :allow-empty="true"
      track-by="id"
      label="name"
      :reset-after="true"
      select-label=""
      selected-label=""
      deselect-label=""
      :searchable="true"
      :placeholder="placeholder"
      :show-no-results="true"
      :loading="loading"
      :clear-on-select="true"
      :preserve-search="true"
      :internal-search="false"
      @search-change="search"
      @select="select"
    >
      <template
        slot="singleLabel"
        slot-scope="props"
      >
        <span class="option__desc">
          <span class="option__title">
            {{ props.option.name }}
          </span>
        </span>
      </template>
      <template
        slot="option"
        slot-scope="props"
      >
        <div class="option__desc">
          <span class="option__title">
            <strong>{{ props.option.name }}</strong> 
            ({{ props.option.email }}) 
            <em>{{ props.option.position ? ` - ${props.option.position}` : '' }}</em>
            <strong>
              {{
                props.option.usergroup && props.option.usergroup.display_name ?
                  ` - ${props.option.usergroup.display_name}` :
                  ''
              }}
            </strong>
          </span>
        </div>
      </template>
      <template slot="clear">
        <div
          v-if="selection"
          class="multiselect__clear"
          @click.prevent.stop="deselectContact"
        >
          <b-icon-x
            v-if="!disabled"
            font-scale="2"
          />
        </div>
      </template>
      <span slot="noResult">
        {{ $t('searchbars.noResult') }}
      </span>
      <span slot="noOptions">
        {{ $t('searchbars.noOptions') }}
      </span>
    </Multiselect>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

import { mapState, mapActions } from 'vuex';

export default {
  name: 'SearchContacts',

  components: {
    Multiselect
  },

  props: {
    contact: {
      type: Object,
      default: () => { return {}; }
    },
    usergroups: {
      type: Array,
      default: () => { return []; }
    },
    resetAfter: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: 'Rechercher un contact existant ...'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    limit: {
      type: Number,
      default: 10
    },
    currentContacts: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },

  data() {
    return {
      selection: null,
      loading: false,
      text: null,
      results: []
    };
  },

  computed: {
    ...mapState('usergroups', [
      'contactsList'
    ])
  },

  watch: {
    contact: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.selection = newValue;
        }
      }
    },
    currentContacts: {
      deep: true,
      handler(newValue) {
        this.results = this.contactsList.filter(el => newValue.every(c => c.id !== el.id));
      }
    },
    text: function(newValue) {
      this.loading = true;
      this.SEARCH_CONTACTS({ text: newValue, usergroups: this.usergroups })
        .then(() => {
          this.results = this.contactsList.filter(el => this.currentContacts.every(c => c.id !== el.id));
          this.loading = false;
        })
        .catch((err) => {
          console.error(err);
          this.loading = false;
        });
    }
  },

  async created() {
    this.loading = true;
    await this.GET_CONTACTS_LIST({});
    this.results = this.contactsList.filter(el => this.currentContacts.every(c => c.id !== el.id));
    if (this.contact && this.contact.name) {
      this.selection = this.contact;
    } else if (this.results.length === 1) {
      this.selection = this.results[0];
    }
    this.loading = false;
  },

  methods: {
    ...mapActions('usergroups', [
      'SEARCH_CONTACTS',
      'GET_CONTACTS_LIST'
    ]),

    search(text) {
      this.text = text;
    },

    select(e) {
      if (e) {
        this.$emit('select', e);
      } else {
        this.$emit('select', {
          name: null,
          id: null,
          email: null,
          phone_number: null,
          role: null,
          usergroup: null
        });
      }
    },

    deselectContact() {
      this.text = null;
      this.selection = null;
      this.$emit('select', {
        name: null,
        id: null,
        email: null,
        phone_number: null,
        role: null,
        usergroup: null
      });
      this.$refs.multiselect.toggle();
    }
  }
};
</script>

<style lang="less" scoped>

.disabled {
  pointer-events: none;
  opacity: 0.6;
}

</style>
